@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
    height: 100%;
    width: 100%;
    margin: 0;
}

@layer base {
    :root {
        --background: #14172D;
        --background-light: #F1F1F5;
        --foreground: #FFFFFF;

        --muted: #252238;
        --muted-foreground: #FFFFFF;

        --popover: #14172D;
        --popover-foreground: #FFFFFF;

        --card: #14172D;
        --card-foreground: #FFFFFF;

        --border: #312E47;
        --input: #14172D;

        --primary: #B3005E;
        --primary-foreground: #FFFFFF;

        --secondary: #252238;
        --secondary-foreground: #8B90B2;

        --accent: #8FCA5C;
        --accent-foreground: #242C36;

        --destructive: #ff548a;
        --destructive-foreground: #222732;

        --success: #d8fde0;
        --success-foreground: #222732;

        --info: #8eb2ff;
        --info-foreground: #222732;

        --warning: #ffcd47;
        --warning-foreground: #222732;

        --ring: 215 20.2% 65.1%;

        --radius: 0.5rem;
    }
}

p {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
}

.gradient {
    padding-right: 0.25rem;
    background: var(--gradient);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient.alt {
    padding-right: 0.25rem;
    background: var(--gradient-alt);
    background-clip: border-box;
    -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
