@use "marketing";


html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

:root {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  --body: #242c36;
  --body-secondary: #fff;
  --cta: #242c36;
  --border: #242c36;
  --border-light: #C28340;
  --foreground: #61371F;
  --foreground-secondary: #854F2B;
  --background: rgb(39 37 35);
  --greyed-out: #798ca5;
  --gradient-alt: linear-gradient(56deg, #aa69f4 45.24%, #373fef 60.74%);
  --shadow: #61371F;
}

body {
  margin: 0;
  background-color: var(--background);
  color: rgb(235 234 234);
}

.marketing.wrapper {
  height: 100vh;
  position: relative;
  max-width: 1280px;
  margin: auto;
  padding: 2.5rem 2rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;
  overflow-x: hidden;
  container: wrapper / inline-size;
}

.marketing {
  section {
    width: 100%;
    max-width: 100%;
    container: section / normal;
  }

  section:not(.pricing) {
    padding: 0 1rem;
  }

  section.pricing h3 {
    padding-left: 1rem;
  }
}

@media (max-width: marketing.$mobile-breakpoint) {
  .marketing.wrapper {
    max-width: none;
    width: 100%;
    padding: 1rem 0;
  }
}

.marketing {
  button {
    display: flex;
    padding: 1rem 2rem;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: marketing.$button-radius;
    border: 3px solid var(--border);
    background: var(--accent);

    color: var(--cta);
    text-align: center;
    font-family: "Poppins";
    font-size: marketing.$button-font-size;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  button.small {
    font-size: marketing.$small-font-size;
  }

  button:hover {
    border-color: #fff;
  }
  s button:focus,
  button:focus-visible {
    outline: 4px auto -webkit-focus-ring-color;
  }
}

/* Typography */

.marketing {
  // soft reset
  p,
  a,
  h1,
  h2,
  h3 {
    margin: 0;
    padding: 0;
  }

  p.main,
  span.main {
    font-family: "Poppins";
    font-size: marketing.$medium-font-size;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  p.secondary,
  a.secondary {
    font-family: "Poppins";
    font-size: marketing.$small-font-size;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
  }

  a.menu-text {
    font-family: "Poppins";
    font-size: marketing.$medium-font-size;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
  }

  p.small,
  p.secondary.small {
    font-family: "Poppins";
    font-size: marketing.$small-font-size;
    font-weight: 400;
  }

  p.italic {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 400;
    line-height: 125%;
  }

  a.main,
  a.main:visited,
  a.main:active {
    font-family: "Poppins";
    font-weight: 400;
    text-decoration: inherit;
    cursor: pointer;
    color: var(--greyed-out);
  }

  a.main:hover,
  a.secondary:hover {
    font-family: "Poppins";
    color: var(--shadow);
  }

  h3.main {
    font-family: MinecraftTen, Poppins;
    font-size: marketing.$main-font-size;
    font-weight: 500;
    //color: var(--greyed-out);
    margin-bottom: 1rem;
  }

  h2 {
    color: white;
  }

  @media (max-width: marketing.$mobile-breakpoint) {
    p.main,
    span.main,
    p.italic {
      font-size: clamp(marketing.$small-font-size, 3vw, 2rem);
      line-height: 150%;
    }

    p.secondary,
    a.secondary {
      font-size: clamp(1.2rem, 3vw, 2rem);
    }
  }
}

/* Header */

.marketing {
  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    h2 {
      @include marketing.gradient-logo;
    }

    .menu {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: 2em;
      list-style: none;
    }
  }

  @container (max-width: 1174px) {
    header {
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;

      h2 {
        font-size: clamp(marketing.$main-font-size, 4vw, 3.5rem);
      }
    }
  }
}

/* Hero */

.marketing {
  .hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    p {
        font-family: "Poppins", sans-serif;
        font-size: 2rem;
        font-weight: 500;
    }

    .left {
      flex: 0 1 25%;
    }

    .right {
      flex: 1 0 70%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

    }

    .content {
      display: flex;
      gap: 4vw;
      width: 100%;
      text-align: right;
      font-size: clamp(2.5rem, 5vw, 4.0rem);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      font-family: "Poppins", sans-serif;
    }
  }

  @media (max-width: marketing.$mobile-breakpoint) {
    .hero {
      flex-direction: column;
      align-items: center;

      .preview {
        order: 1;
        width: 100%;
      }

      .content {
        order: 0;
        flex-direction: column;
        text-align: center;

        p {
          margin: 2rem 0;
        }

        .cta {
          padding-top: 1rem;
        }
      }

      .button-wrapper {
        margin-top: 2rem;
      }

      .front {
        padding: 1.75rem 4rem;
      }
    }
  }
}

/* About */

.marketing {
  .about {
    .testimonial {
      height: 12.5rem;
      display: flex;
      align-items: center;
    }

    img.preview {
      max-width: 100%;
      border-radius: 0.75rem;
      border: 6px solid #e1c7fb;
      margin: 2rem 0;
    }
  }

  @media (max-width: marketing.$mobile-breakpoint) {
    .about {
      img.preview {
        max-width: 96%;
      }
    }
  }
}

/* Utility Styles */

span.bold {
  font-family: "Poppins";
  font-weight: 700;
}

